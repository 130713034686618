<template>
  <div class="xadmin-table">
    <div class="card">
      <DataTable :value="products" tableStyle="min-width: 50rem" removableSort>
        <Column field="code" header="Code" sortable style="width: 25%"></Column>
        <Column field="name" header="Name"></Column>
        <Column field="category" header="Category"></Column>
        <Column field="quantity" header="Quantity"></Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup"; // optional
import Row from "primevue/row"; // optional

export default {
  name: "XadminTable",

  components: {
    DataTable,
    Column,
    ColumnGroup,
    Row,
  },

  setup() {
    const products = ref([
      {
        code: "f230fh0g3",
        name: "Bamboo Watch",
        category: "Accessories",
        quantity: 24,
      },
      {
        code: "nvklal433",
        name: "Black Watch",
        category: "Accessories",
        quantity: 61,
      },
      {
        code: "zz21cz3c1",
        name: "Blue Band",
        category: "Fitness",
        quantity: 2,
      },
      {
        code: "244wgerg2",
        name: "Blue T-Shirt",
        category: "Clothing",
        quantity: 25,
      },
      {
        code: "h456wer53",
        name: "Bracelet",
        category: "Accessories",
        quantity: 73,
      },
    ]);

    return {
      products,
    };
  },
};
</script>

<style lang="scss" scoped>
.xadmin-table table thead {
  border: 1px solid #c52424;
}
.xadmin-table table td,
.xadmin-table table th {
  border: 1px solid #c52424;
  padding: 8px;
}
</style>