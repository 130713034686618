<template>
  <XadminLayout>
    <XadminTable />
  </XadminLayout>
</template>

<script>
import XadminTable from "@/modules/xadmin/components/table";
import XadminLayout from "@/modules/xadmin/layout";

export default {
  name: "xadmin-contacts",

  components: {
    XadminLayout,
    XadminTable,
  },
};
</script>

<style>
</style>